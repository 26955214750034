<template>
  <div class="">
      <headers />
    <div class="mb" style="padding:15px;padding-top: 60px !important;">
      
    </div>

    <div  >
      <div class="col-lg-12">
            <div class="search-result-box">
             
        <div class="tab-content" style="width:100%;background:#fff">
        <div style="width: 20%;float:left;"> 
                           
     
        <div class="lang" v-html="lan.address" ></div>:<br>
        <input class="form-control" id="address" style="width:200px;display:inline-block;flex: none !important;" type="text" placeholder="Search" aria-label="Search" v-model="address" >
      
      <div style="width: 100%;height:10px"></div>
      <div class="lang" v-html="lan.distance" ></div>: (m) <div class="help">
        <ctooltip :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'" :align="'right'" :help_text="lan.help_cbreai_distance"/>
        </div><input class="form-control mr-1" style="width:90px !important; margin-left: 23px;display:inline-block; flex: none !important;" type="text" placeholder="Distance" aria-label="Distance" v-model="distance" >
                                
        <div style="width: 100%;height:10px"></div>
         <div class="lang" v-html="lan.data_type" ></div>:<br>
         <div class="types">
          <div class="custom-input">
          <input type="radio" value="plan" v-model="type" ><div class="show-box radio" ></div></div>  <div class="lang" v-html="lan.plan" ></div>  &nbsp;&nbsp;&nbsp;&nbsp; <div class="custom-input"><input type="radio" value="complated"  v-model="type" ><div class="show-box radio" ></div></div> <div class="lang" v-html="lan.complated" ></div>
         </div>
         <div style="width: 100%;height:10px"></div>
         <div class="lang" v-html="lan.completed_year" ></div>:<br>
         <input class="form-control mr-1" style="width:95px !important;display:inline-block; flex: none !important;" type="text"  v-model="completed_start" >&nbsp;<input class="form-control mr-1" style="width:95px !important;display:inline-block; flex: none !important;" type="text"  v-model="completed_end" />
        <div style="width: 100%;height:10px"></div>
        <div class="lang" v-html="lan.gfa_" ></div>:<br>
         <input class="form-control mr-1" style="width:95px !important;display:inline-block; flex: none !important;" type="text"  v-model="gfa_start" >&nbsp;<input class="form-control mr-1" style="width:95px !important;display:inline-block; flex: none !important;" type="text"  v-model="gfa_end" />
        <div style="width: 100%;height:10px"></div>
        <div class="lang" v-html="lan.areas" ></div>:<br>
        <select class="form-control mt-0" style="width:200px !important;" v-model="area" >
        
                                          <option v-for="(item,index) in areas" :key="index" :value="item.code">{{cur_lan=="jp"?item.name_jp:item.name_en}}</option>
                          
                                        </select>
              
         <div style="width: 100%;height:10px"></div>
         <span class="search-btn" >
              <button type="button" class="btn waves-effect waves-light btn-blue" @click="search"><i class="fa fa-search mr-1"></i> <div class="lang" v-html="lan.search" ></div></button>
          </span>
         </div>
            <div class="embed-responsive-16by9 tab-pane show active tc1" style="width: 80%;position:relative; float:left;" id="tc1" >
            <div class="d-flex justify-content-center p-5 loading" v-if="isLoading">
                                  <div class="spinner-border m-12" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                <div class=" mb-2" style="position:relative">
                
                  <div id="googleMap" class="google"></div>
                </div>
                <div class="col-12">
                  <div class="responsive-table-plugin">

                    <div class="table-rep-plugin">
                      <div class="table-responsive">
                        <table class="table table-striped focus-on">
                          <thead>
                            <tr>
                              <th v-for="tableHeader in tableHeaders" :key="tableHeader" :class="'table-row-' + tableHeader" ><div class="lang" v-html="lan[tableHeader]" ></div></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody v-if="mapList.length>0">
                            <tr v-for="(obj, idNum) in mapList" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="toggleFocus(idNum)">
                              
                              <td >
                              {{obj['BuildingName']}}
                              </td>
                               <td >
                              {{(obj['CompletionDate']=='NaT'?'':obj['CompletionDate'])}}
                              </td>
                               <td >
                              {{(obj['Address']==undefined ?'':obj['Address'])}}
                              </td>
                               <td >
                              {{(obj['TotalGFA']==undefined ?'':obj['TotalGFA'])}}
                              </td>
                               <td >
                              {{(obj['distance']==undefined ?'':obj['distance'])}}
                              </td>
                              <td class="table-focus-on-td">
                              <transition transition name="slide-btn">
                                <router-link v-if="isFocus === idNum" target="_blank" :to="'/build_info?BuildingID='+obj['BuildingID']+'&PropertyCode='+obj['PropertyCode']+'&tolink=/#/search_building'" class="btn btn-default">詳細 <i class="mdi mdi-arrow-right-bold"></i></router-link>
                              </transition>
                            </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="p-2">
                          <code v-if="mapList.length==0"><div class="lang" v-html="lan.no_data"></div></code>
                        </div>
                       
                      </div> <!-- end .table-responsive -->
                    </div> <!-- end .table-rep-plugin-->
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
//import ListTableRow from '@/components/ListTableRow.vue'
import Headers from '@/components/Headers.vue'
import router from '@/router'
import axios from 'axios';
import { inject } from 'vue';
import Ctooltip from '@/components/Ctooltip';
//import { MarkerClusterer } from "@googlemaps/markerclusterer";
export default {
  name: "search_result",
   components: {
    //ListTableRow,
    Headers,Ctooltip
  },
  data() {
    const { currentRoute } = router
    return {
      center: { lat: Number, lng: Number },
      address: currentRoute.value.query.keyword==undefined ? "":currentRoute.value.query.keyword,
      distance:500,
      mapList:[],
      reload: 0,
      cad:'',
      map:null,
      google: {
        maps: {
          StreetViewPanorama: {},
          Map: {},
          MapTypeId: {},
          Marker:{},
          InfoWindow:{},
          Circle:{},
        },
      },
      markers:[],
      infowindow:[],
      curindex:"tc1",
      tableHeaders:['building_name','completed_year','address','TotalGFA_SqM','distance'],
      // company
       viewParams: {
        name: 'CorpReference',
        apiUrl: '/v1/corps_reference/',
        articleView: 'CorpReferenceArticle',
        articleParamId: 'id',
        dataType:100,
      },
      offset: 0,
      listStrage: {
        query:'',
        limit: 100000,
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object]
      },
      tableHeaders2: [],
      listData: [],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: false,
      downloadAlert: '',
      
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      cur_lan:'jp',
      ccenter:'',
      radius:0,
      isPopup:false,
      areas:[],
      area:'tky23',
      type:'plan',
      completed_start:'',
      completed_end:'',
      gfa_start:'',
      gfa_end:'',
    };
  },
  $route(){    
    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
   },
  mounted() {
    const { currentRoute } = router;
    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
     this.address=currentRoute.value.query.keyword==undefined ? "":currentRoute.value.query.keyword;
      axios.get(process.env.VUE_APP_API2_URL_BASE+'/getBuildingAreas',{params:{id_token:localStorage.getItem('id_token')}})
          .then(response => {
              this.areas=response.data.data;
              this.loadGoogleMapsScript().then(() => {
                this.google = window.google;
                this.autoaddress()
                this.initializeMap();
              });  
          })
          .catch(error => (
              console.log(error)
            )
          );
    
   
   
 },
  methods: {
    initdraw(map){
      var _that=this;
      var drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.CIRCLE,
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.CIRCLE],
            },
          
            circleOptions: {
            strokeColor: "#003f2d",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#003f2d",
              fillOpacity: 0.35,
              clickable: false,
              editable: true,
              zIndex: 1,
            },
          });

      drawingManager.setMap(map);
      window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
        if (event.type == 'circle') {
          _that.ccenter=JSON.parse(JSON.stringify(event.overlay.getCenter()));
          _that.radius=parseInt(event.overlay.getRadius());
          _that.distance=_that.radius;
          console.log(_that.radius+','+_that.distance+"<1>")
          new window.google.maps.Geocoder().geocode({ location :event.overlay.getCenter() }, function (results,status) {
            console.log(results);
            console.log(_that.ccenter);
            if (status === "OK" && results[0]) {
        
            _that.address=results[0].formatted_address;
            document.querySelector("#address").value=_that.address;
            _that.radius=parseInt(event.overlay.getRadius());
            _that.distance=_that.radius;
            console.log(_that.radius+','+_that.distance+"<1.5>")
              _that.search();
              window.google.maps.event.addListener(event.overlay, 'radius_changed', function() {
              _that.radius=parseInt(event.overlay.getRadius());
              _that.distance=_that.radius;
              console.log(_that.radius+','+_that.distance+"<2>")
              _that.search();
              })
            }
          });
        }
        
      });
    },
    autoaddress(){

      let address1Field;
          address1Field = document.querySelector("#address");
          new this.google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ["jp"] },
          fields: ["address_components", "geometry"],
          types: ["address"],
          });
          address1Field.focus();

    },
     toggleDDMenu: function() {
      this.ddMenu = !this.ddMenu
    },
    allCheck: function() {
      this.checkKeys = this.tableHeaders2
    },
    allUncheck: function() {
      this.checkKeys = []
    },
    toggleFocus: function(idNum) {
      this.isFocus = idNum;
    },
    search: function() {
      
      this.address=document.querySelector("#address").value;
      console.log(this.ccenter)
      console.log(this.radius+','+this.distance+"<3>")
      this.isLoading=true;
        axios.get(process.env.VUE_APP_API2_URL_BASE+'/getLocationByAddresBuilding?address='+ this.address+'&type='+this.type+'&area='+this.area+'&completed_start='+this.completed_start+'&completed_end='+this.completed_end+'&gfa_start='+this.gfa_start+'&gfa_end='+this.gfa_end+'&center=&distance='+ this.distance,{params:{
            offset: this.offset, //開始位置
            limit: this.listStrage.limit, //表示件数,
            id_token:localStorage.getItem('id_token')}})
          .then(response => {
            
              this.mapList=response.data.data;
              this.ccenter=response.data.center;
              this.initializeMap();
               this.isLoading = false
          })
          .catch(error => (
              console.log(error),
              this.jsonErr = true,
              this.isLoading = false
            )
          );
     
    },
    pagenateNum: function(num) {
      this.offset = (num - 1) * this.listStrage.limit;
      this.search();
    },
    pagenateKey: function(key) {
      switch(key) {
        case 'next':
          if( this.listStrage.paged < this.maxPage ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * this.listStrage.paged
          }
          break
        case 'prev':
          if( this.listStrage.paged > 1 ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.listStrage.paged - 2)
          }
          break
        case 'first':
          if( this.listStrage.paged > 1 ) {
            this.isLoading = true
            this.offset = 0
          }
          break
        case 'last':
          if( this.listStrage.paged < this.maxPage ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.maxPage - 1)
          }
          break
        default:
          this.isLoading = false
          console.log('Non-existent key')
      }
      this.search();
    },
    pageHide: function(num) {
      if( num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4 ) {
        return true
      }
    },
    
    tab:function(tid){
      this.curindex=tid;
      if(tid=="tc1"){
        var that=this;
        setTimeout(function(){
          that.initializeMap();
        },800)
          
      }
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=places,drawing,geometry&v=weekly&channel=2";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 5000);
      });
    },
    initializeMap() {
      const mapContainer = this.$el.querySelector("#googleMap");
      
      const { Map, MapTypeId, Marker,InfoWindow,Circle} = this.google.maps;
      const Geocoder = new this.google.maps.Geocoder();
      var _that=this;
      this.cad='';
      // map create
      var zoom=7
      if(_that.distance>3000000){
        zoom=3
      }else if(_that.distance>2000000){
        zoom=4
      }else if(_that.distance>1000000){
        zoom=5
      }else if(_that.distance>500000){
        zoom=6
      }else if(_that.distance>200000){
        zoom=7
      }else if(_that.distance>100000){
        zoom=8
      }else if(_that.distance>50000){
        zoom=9
      }else if(_that.distance>20000){
        zoom=10
      }else if(_that.distance>10000){
        zoom=11
      }else if(_that.distance>5000){
        zoom=12
      }else if(_that.distance>2000){
        zoom=13
      }else if(_that.distance>1000){
        zoom=14
      }else if(_that.distance>500){
        zoom=15
      }else if(_that.distance>200){
        zoom=16
      }else if(_that.distance>100){
        zoom=17
      }else if(_that.distance>50){
        zoom=18
      }else if(_that.distance>20){
        zoom=19
      }else{
        zoom=20;
      }
      _that.map = new Map(mapContainer, {
        zoom: zoom,
        mapTypeId: MapTypeId.ROADMAP,
      });

    let addr='';
    if(_that.address==""){
      for(var d in _that.areas){
        if(_that.areas[d].code==_that.area){
          addr=_that.areas[d].name_jp;
        }
      }
      
    }else{
      addr=_that.address
    }
    console.log(addr);

      new Geocoder.geocode({ address: addr }, function (results, status) {
        if (status === "OK" && results[0]) {
          let locate = results[0].geometry.location;
          
          let center = {
            lat: parseFloat(locate.lat()),
            lng: parseFloat(locate.lng()),
          };
          if(_that.ccenter!=null && _that.ccenter.lat!=null){
            center.lat=_that.ccenter.lat;
            center.lng=_that.ccenter.lng;
          }
          
          //_that.ccenter=center;
          // set locate
          _that.map.setCenter(center);
          var cc=new Circle({
            strokeColor: "#003f2d",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#003f2d",
            fillOpacity: 0.35,
            map:_that.map,
            center: center,
            editable:true,
            radius: parseInt(_that.distance),
          });
          _that.google.maps.event.addListener(cc, 'radius_changed', function() {
            _that.radius=parseInt(cc.getRadius());
            _that.distance=_that.radius;
            _that.search();
          
          });      
          //_that.initdraw(_that.map)
          //_that.mapList.push({latitude:locate.lat(),longitude:locate.lng(),address:_that.address})
          _that.markers=[];
          _that.infowindow=[];
          if(_that.mapList.length>0){
            _that.mapList.map((obj,i) => {
               _that.infowindow[i] = new InfoWindow({
                content: '<div id="content">' +
                            '<div id="siteNotice">' +
                            "</div>" +
                            '<h3 id="firstHeading" class="firstHeading">'+(obj.BuildingName==undefined ?'':obj.BuildingName)+'</h3>' +
                            '<div id="bodyContent">' +
                            "<p>竣工日 ："+(obj['CompletionDate']==undefined || obj['CompletionDate']=='NaT' ?'':obj['CompletionDate'].substr(0,10))+"<br>住所： "+obj.Address+"</p>" +
                            "</div>" +
                            "</div>"
              });
              _that.markers[i]=new Marker({
                position: {
                          lat: parseFloat(obj.MapCenterLat),
                          lng: parseFloat(obj.MapCenterLong)},
                map:_that.map,
                title: obj.BuildingName==undefined ?'':obj.BuildingName,
                icon: "https://cbredata.s3.ap-northeast-1.amazonaws.com/images/lou.png",
              });
              _that.markers[i].addListener("click", () => {
              _that.infowindow[i].open({
                anchor: _that.markers[i],
                map:_that.map,
                shouldFocus: false
              });
            });

            });
            
           
          }  
          //pano.setPosition(_that.center);
        } else {
          console.log("failed load google api  : " + status);
          return;
        }
      });
    },
  
    gotowindow(i){
      var _that=this;
      if(this.cad!=''){
        _that.infowindow[this.cad.replace('k','')].close();
      }
      this.cad='k'+i;
      _that.infowindow[i].open({
        anchor: _that.markers[i],
        map:_that.map,
        shouldFocus: false
      });
    }
  },
  watch: {

    $route(){
      
    },
    
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.search-result-box {
     width: 100%;
    display: table;
}
.search-btn{margin:10px;}
.google {
  height: 550px;
}
.overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 60px;
  line-height: 80px;
  opacity: 0.8;
  background: #4477aa;
  border: solid 3px #336699;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #333;
  text-shadow: 1px 1px 1px #666;
  padding: 0 4px;
}
.loading{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,0.5);z-index:999;}
.overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #336699;
}
.overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #336699;
}
.row {
  font-size: 14px;
}
pre {
  font-family: 'Ubuntu Mono';
  font-size: 14px;
}
.row {
  zoom: 1;
  margin-left: -20px;
}
.row:before,
.row:after {
  display: table;
  content: "";
  zoom: 1;
}
.row:after {
  clear: both;
}
.row>[class*="span"] {
  display: inline;
  float: left;
  margin-left: 20px;
}
.span1 {
  width: 40px;
}
.span2 {
  width: 100px;
}
.span3 {
  width: 160px;
}
.span4 {
  width: 220px;
}
.span5 {
  width: 280px;
}
.span6 {
  width: 340px;
}
.span7 {
  width: 400px;
}
.span8 {
  width: 460px;
}
.span9 {
  width: 520px;
}
.span10 {
  width: 580px;
}
.span11 {
  width: 640px;
}
.span12 {
  width: 700px;
}
.span13 {
  width: 760px;
}
.span14 {
  width: 820px;
}
.span15 {
  width: 880px;
}
.span16 {
  width: 940px;
}
.span17 {
  width: 1000px;
}
.span18 {
  width: 1060px;
}
.span19 {
  width: 1120px;
}
.span20 {
  width: 1180px;
}
.span21 {
  width: 1240px;
}
.span22 {
  width: 1300px;
}
.span23 {
  width: 1360px;
}
.span24 {
  width: 1420px;
}
pre.prettyprint {
  background: #fff;
  border-color: #c1c1c1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 10px #999;
  -moz-box-shadow: 0 0 10px #999;
  box-shadow: 0 0 10px #999;
  padding: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.label {
  font-size: 10.998px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999999;
  margin: 2px 6px;
}
.label {
  padding: 1px 4px 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.notice {
  background-color: #3a87ad;
}
form input[type=text] {
  border: solid 1px #999999;
  padding: 2px;
}
.edit_marker {
  width: 250px;
  height: 80px;
}
.edit_marker p {
  margin: 0;
}
.address_list {position:absolute; top:80px;left:30px;z-index: 999;background: #FFF;max-height: 500px;overflow-y: auto;}
.address_list .line{border-top:1px solid #eee;padding: 5px;margin:0 5px;     cursor: pointer;}
.address_list .line:nth-child(0){
  border-top:0;
}
.address_list .active{background: #003f2d; color:#fff;}
.nav-tabs li{cursor: pointer;}

.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {

  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: .75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: .5em;
    width: 100%;
  }

}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: .15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -.5rem;
  top: -.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {

  .dropdown-menu.show li {
    flex-basis: 50%;
  }

}

@media screen and (max-width: 500px) {

  .dropdown-menu.show li {
    flex-basis: 100%;
  }

}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover, table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: .35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: .15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
.table td .table-data-container{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}
.types{margin-left: 10px;}
.help{position: relative;margin-left: 10px;    display: inline-block;}
.help-box{margin-top:10%;width: 60%;}
.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.popup {
  background: #fff;
  border-radius: .15rem;
  box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 90%;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
.tagbox{
    display: flex;
    align-items: center;
    justify-content: center;
  }
.closes{position: absolute;
cursor: pointer;
        right: 16px;
    top: 4px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    padding: 2px 9px;
    border-radius: 15px;}

</style>
